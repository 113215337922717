import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import PortableText from 'react-portable-text';
import { Link, StaticQuery, graphql } from 'gatsby';
import logo_car from '../../animations/car-logo/logo_car.json';
import FancyTitle from '../fancy-title/FancyTitle';
import dnbPresenterer from '../../images/dnb-presenterer.png';
import Stamp from '../stamp/Stamp';
import { pageLoad, trackEvent } from '../../utils/track';
import { Button } from '@dnb/eufemia/components';

import * as styles from './Start.module.scss';

const Start = ({ challenge }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          sanityFrontpage {
            title
            text {
              ...BlockContent
            }
            buttonText
          }
        }
      `}
      render={(data) => {
        return (
          <StartSection
            title={data.sanityFrontpage.title}
            text={data.sanityFrontpage.text}
            cta={data.sanityFrontpage.buttonText}
            challenge={challenge}
          />
        );
      }}
    />
  );
};

const StartSection = ({ title, text, cta, challenge }) => {
  useEffect(() => {
    // Tracking
    pageLoad();
  }, []);

  const carLogoOptions = {
    loop: true,
    autoplay: true,
    animationData: logo_car,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const carLogoStyle = {
    maxWidth: '400px',
    maxHeight: '400px',
    minHeight: '300px',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  };

  return (
    <section className={styles.main}>
      <div className={styles.stampBox}>
        <Stamp />
      </div>
      <img
        alt="DNB presenterer"
        className={styles.dnbPresentes}
        src={dnbPresenterer}
        width="400"
      />
      <Lottie options={carLogoOptions} style={carLogoStyle} />
      <div className={styles.textContent}>
        <h1>
          <FancyTitle text={title} />
        </h1>
        <div className={styles.text}>
          <PortableText content={text} />
        </div>
        {challenge && <ChallengeStart challenge={challenge} />}
        <div className={styles.buttons}>
          <Link
            className={styles.startButton}
            to="/quiz"
            onClick={() => {
              trackEvent(
                'General Interaction',
                'Click on CTA Link',
                'Ta testen',
                'https://sjekklappen.dnb.no/quiz'
              );
            }}
          >
            {cta}
          </Link>
          <Button
            target={'_blank'}
            href={
              'https://www.dnb.no/forsikring/bilforsikring?mcc=FAST_forsikring_bil_sjekklappen'
            }
            onClick={() => {
              trackEvent(
                'Outbound Link',
                'Click on CTA Link',
                'Les mer om vår bilforsikring',
                'https://www.dnb.no/forsikring/bilforsikring?mcc=FAST_forsikring_bil_sjekklappen'
              );
            }}
          >
            Les mer om vår bilforsikring
          </Button>
        </div>
      </div>
    </section>
  );
};

const ChallengeStart = ({ challenge }) => {
  if (!challenge.active) {
    return null;
  }
  return (
    <div className={styles.challengeText}>
      <p>
        Se om du kan slå {challenge.name} som fikk {challenge.score} poeng
      </p>
    </div>
  );
};

export default Start;
