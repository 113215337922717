import React, { useContext, useState, useEffect } from 'react';
import Layout from '../components/layout/Layout';
import Seo from '../components/seo/Seo';
import Start from '../components/start/Start';
import queryString from 'query-string';
import { StatusContext } from '../contexts/StatusProvider';

const IndexPage = ({ location }) => {
  const [challengeQuery, setChallengeQuery] = useState(null);
  const [status, dispatch] = useContext(StatusContext);

  useEffect(() => {
    setChallengeQuery(queryString.parse(location.search));
  }, [location]);

  useEffect(() => {
    if (
      challengeQuery &&
      challengeQuery.challenge &&
      status.challenge.active === false
    ) {
      dispatch({
        type: 'start_challenge',
        payload: {
          name: challengeQuery.challenger,
          score: challengeQuery.score,
        },
      });
    }
  }, [challengeQuery, status]);

  const challenge = status ? status.challenge : null;

  return (
    <Layout>
      <Seo />
      <Start challenge={challenge} />
    </Layout>
  );
};

export default IndexPage;
